#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html, body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.5s
}

.app-main {
  position: relative;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
}

.app-content-sidebar-fixed {
  min-height: 100vh;
}

.app-main-sidebar-static {
  display: flex;
}

.app-content-footer-fixed {
  height: auto;
}
